import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';

import logoOB from '../img/logo-ob.jpg'

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
});

const tileData = [
    {
      title: 'Order Beast',
      img: logoOB,
      link: 'https://orderbeast.com.au/'
    },
    {
      title: 'VR UIKit',
      img: 'https://assetstorev1-prd-cdn.unity3d.com/key-image/d5b88089-a6e0-4fb6-a4b8-9340290fd69b.jpg',
      link: 'https://www.assetstore.unity3d.com/#!/content/128236'
    },
    {
      title: 'VR Keyboard',
      img: 'https://assetstorev1-prd-cdn.unity3d.com/key-image/9e1ffa1f-878c-4b5f-a59c-ca82a64c7e06.jpg',
      link: 'https://www.assetstore.unity3d.com/#!/content/95936'
    },
    {
      title: 'VR Inventory',
      img: 'https://assetstorev1-prd-cdn.unity3d.com/key-image/8757992e-2e1e-404d-893b-6e79f84ee4ba.jpg',
      link: 'https://assetstore.unity.com/packages/tools/gui/vr-inventory-for-oculus-147373'
    },
    {
      title: 'TextMeshPro Auto Converter',
      img: 'https://assetstorev1-prd-cdn.unity3d.com/key-image/48ebfedd-10fa-4350-a87f-7bd3c09d2a79.jpg',
      link: 'https://www.assetstore.unity3d.com/#!/content/132691'
    }
  ]

function Products(props) {
  const { classes } = props;
  const getGridListCols = () => {
    if (isWidthUp('md', props.width)) {
      return 4;
    }

    if (isWidthUp('sm', props.width)) {
      return 2.5;
    }

    return 1.5;
  }

  return (
    <div className={classes.root}>
      <GridList className={classes.gridList} cols={getGridListCols()}>>
        {tileData.map(tile => (
          <GridListTile key={tile.img}>
            <img src={tile.img} alt={tile.title} />
            <a href={tile.link} target="_blank" rel="noopener noreferrer">
              <GridListTileBar
                title={tile.title}
                classes={{
                  root: classes.titleBar,
                  title: classes.title,
                }}
              />
            </a>
          </GridListTile>

        ))}
      </GridList>
    </div>
  );
}

Products.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withWidth()(withStyles(styles)(Products));
