import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import hero from '../img/hero.jpeg'

const styles = {
  banner: {
    minHeight: 300,
    height: '50vh',
    width: '100%',
    background: `url(${hero}) no-repeat`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'table',
  },

  title: {
    height: '100%',
    display: 'table-cell',
    verticalAlign: 'middle',
  }
}

function Hero(props) {
  const { classes } = props

  return (
    <div className={classes.banner}>
      <div className={classes.title}>
        <Typography variant="h3" gutterBottom>
        Epibyte Studio
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
        We make state of the art software.
        </Typography>
      </div>
    </div>
  )
}

Hero.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Hero)
