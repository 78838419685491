import React, { Component } from 'react'
// import logo from './logo.png';
import './App.css'
import Header from './components/Header'
import Hero from './components/Hero'
import About from './components/About'
import Footer from './components/Footer'
import CssBaseline from '@material-ui/core/CssBaseline'

class App extends Component {
  render() {
    return (
      <React.Fragment>
        <CssBaseline />
        <div className="App">
          <Header />
          <Hero />
          <About />
          <Footer />
        </div>
      </React.Fragment>
    )
  }
}

export default App
