import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import logoTwitter from '../img/logo-twitter.png'

const styles = ({
  footer: {
    background: '#212121',
    padding: '100px 0',
  },
  footerText: {
    color: 'white',
    padding: '1em'
  },
  socialIcon: {
    width: '2em',
    height: '2em',
  }
})

function Footer(props) {
  const { classes } = props

  return (
    <div className={classes.footer}>
      <a href="https://twitter.com/epibytes" target="_blank" rel="noopener noreferrer">
        <img className={classes.socialIcon} src={logoTwitter} alt="Twitter"/>
      </a>
      <Typography variant="body2" gutterBottom className={classes.footerText}>
        &copy;2018-2019 Epibyte. All Rights Reserved.
      </Typography>
    </div>
  )
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Footer)
