import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import Products from './Products'

const styles = theme => ({ /** */ })

function About(props) {
  return (
    <div className="Content">
      <Typography variant="body2" gutterBottom>
        We are a team of software craftsmen based in Melbourne.
        Our products include:
      </Typography>
      <Products />
      <br/>
      <Typography variant="body2" gutterBottom>
        If you are interested in potential collaboration with us, please
        reach us through
        &nbsp;
        <a href="mailto:yunhn.lee@gmail.com">
          Email
        </a>
      </Typography>
    </div>
  )
}

About.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(About)
